import {
  mdiAccountOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
  mdiShieldStarOutline
} from "@mdi/js";
import { computed, nextTick, ref } from "@vue/composition-api";
import { useFind, useGet } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
const { User, Profile } = Vue.$FeathersVuex.api;
import { pwnedPassword } from "hibp";

import { snackbar } from "@/components/base/snackbar/useSnackbar";
import { store } from "@/store";

export function useUser() {
  const { route } = useRouter();
  const {
    item: user,
    isPending,
    hasLoaded
  } = useGet({
    model: User,
    id: route.value.params.id
  });

  return {
    user,
    isPending,
    hasLoaded
  };
}

export function getUserCount() {
  const userCount = ref(0);

  try {
    let params = { query: {} };
    store.dispatch("users/count", params).then((response) => {
      userCount.value = response;
    });
  } catch (error) {
    console.log(error);
  }

  return {
    userCount
  };
}

export function useUsersList() {
  const usersListTableHeaders = [
    { text: "USER", value: "fullName" },
    { text: "EMAIL", value: "email" },
    { text: "ROLE", value: "role", sortable: false },
    { text: "STATUS", value: "status", sortable: false },
    {
      text: "ACTIONS",
      value: "actions",
      align: "center",
      sortable: false
    }
  ];

  const resolveUserRoleVariant = (role) => {
    if (role === "Super Administrator") return "primary";
    if (role === "Site Adminsitrator") return "warning";
    if (role === "Construction Designer") return "success";
    if (role === "Construction Manager") return "info";
    if (role === "Reporter") return "error";

    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "Super Administrator") return mdiShieldStarOutline;
    if (role === "Site Adminsitrator") return mdiCogOutline;
    if (role === "Construction Designer") return mdiDatabaseOutline;
    if (role === "Construction Manager") return mdiPencilOutline;
    if (role === "Reporter") return mdiDnsOutline;

    return mdiAccountOutline;
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "Pending") return "warning";
    if (status === "Active") return "success";
    if (status === "Inactive") return "secondary";

    return "primary";
  };

  const resolveUserStatusAlias = (active) => {
    if (active) return "Active";

    return "Inactive";
  };

  // construct database query
  const usersParams = computed(() => {
    let q = {
      query: {
        $limit: store.state["users"].pageLimit,
        $skip: store.state["users"].pageSkip
      },
      $graphql: {
        profile: true,
        role: true,
        organization: true,
        totalProjectCount: true,
        completedProjectCount: true
      }
    };

    let $and = [];
    if (store.state["users"].filterText !== null) {
      $and.push({
        email: {
          $iLike: "%" + store.state["users"].filterText + "%"
        }
      });
    }
    if (store.state["organizations"].filterOrganizations.length > 0) {
      $and.push({
        org_id: { $in: store.state["organizations"].filterOrganizations }
      });
    }

    if (store.state["roles"].filterRoles.length > 0) {
      $and.push({
        role_id: { $in: store.state["roles"].filterRoles }
      });
    }

    Object.assign(q.query, { $and });
    console.log(q);
    return q;
  });

  // fetch data
  const {
    items: users,
    isPending: usersArePending,
    haveLoaded: usersHaveLoaded, // used for first-load of data table
    paginationData: usersPaginationData,
    latestQuery: usersLatestQuery
  } = useFind({
    model: User,
    params: usersParams,
    paginate: true,
    qid: "userList"
  });

  return {
    usersListTableHeaders,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusAlias,
    users,
    usersArePending,
    usersHaveLoaded,
    usersPaginationData,
    usersLatestQuery
  };
}

export function useUserListInviteDialog() {
  const isUserListInviteDialogOpen = ref(false);
  //const itemToEdit = ref({});
  //const { emit } = getCurrentInstance();
  const user = new User({});
  const userClone = user.clone();
  const profile = new Profile({});
  const profileClone = profile.clone();
  const componentKey = ref(0);

  const createItem = () => {
    //itemToEdit.value = { ...userClone };
    isUserListInviteDialogOpen.value = true;
  };

  const closeDialog = (isEditDialogOpen) => {
    isUserListInviteDialogOpen.value = isEditDialogOpen;
  };

  return {
    isUserListInviteDialogOpen,
    createItem,
    userClone,
    profileClone,
    componentKey,
    closeDialog
  };
}

export function useChangeUserPassword() {
  const initialPasswordChangeForm = {
    oldPassword: null,
    newPassword: null,
    confirmPassword: null
  };

  const passwordChangeForm = ref({ ...initialPasswordChangeForm });

  const isOldPasswordVisible = ref(false);
  const isNewPasswordVisible = ref(false);
  const isConfirmPasswordVisible = ref(false);

  const loading = ref(false);
  const showPwnWarning = ref(false);

  const resetForm = async () => {
    try {
      passwordChangeForm.value = initialPasswordChangeForm;
    } catch (error) {
      console.error("Unable to clear form values");
    } finally {
      await nextTick();
    }
  };
  const onSubmit = async (passwordChangeForm) => {
    try {
      loading.value = true;
      if (
        passwordChangeForm.oldPassword &&
        passwordChangeForm.newPassword &&
        passwordChangeForm.confirmPassword
      ) {
        const numPwns = await pwnedPassword(passwordChangeForm.newPassword);

        if (numPwns > 0) {
          // the password has been pwned
          // reject the password change attempt
          showPwnWarning.value = true;
          snackbar({
            text: `Use of blacklisted passwords is forbidden.`,
            color: "error"
          });

          // resetForm();
        } else {
          let oldPassword = passwordChangeForm.oldPassword;
          let password = passwordChangeForm.newPassword;
          let email = store.state.auth.user.email;
          let options = {
            action: "passwordChange",
            value: {
              user: {
                email: email
              },
              oldPassword,
              password
            }
          };

          await store.dispatch("authManagement/create", options);

          // re-authenticate user instead of redirecting them to Login

          const response = await store.dispatch("auth/authenticate", {
            strategy: "local",
            email,
            password
          });

          localStorage.setItem("tpm-jwt-refresh", response.refreshToken);
          localStorage.setItem("tpm-uid", response.user.id);
        }

        snackbar({
          text: `Your password has been updated!`,
          color: "success"
        });
      }
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to update password. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      loading.value = false;
      resetForm();
    }
  };

  return {
    passwordChangeForm,
    isOldPasswordVisible,
    isNewPasswordVisible,
    isConfirmPasswordVisible,
    loading,
    showPwnWarning,
    onSubmit,
    resetForm
  };
}

export function useResetUserPassword() {
  const { route, router } = useRouter();

  const initialPasswordResetForm = {
    token: route.value.query.token,
    password: null,
    confirmPassword: null
  };

  const passwordResetForm = ref({ ...initialPasswordResetForm });
  const isPasswordVisible = ref(false);
  const isConfirmPasswordVisible = ref(false);

  const loading = ref(false);
  const showPwnWarning = ref(false);

  const resetForm = async () => {
    try {
      passwordResetForm.value = initialPasswordResetForm;
    } catch (error) {
      console.error("Unable to clear form values");
    } finally {
      await nextTick();
    }
  };
  const onSubmit = async (passwordResetForm) => {
    try {
      loading.value = true;
      if (passwordResetForm.token && passwordResetForm.password) {
        const numPwns = await pwnedPassword(passwordResetForm.password);

        if (numPwns > 0) {
          // the password has been pwned
          // reject the password change attempt
          showPwnWarning.value = true;
          snackbar({
            text: `Use of blacklisted passwords is forbidden.`,
            color: "error"
          });
          throw "You are trying to use a blacklisted password.";
          // resetForm();
        } else {
          let password = passwordResetForm.password;
          let token = passwordResetForm.token;
          let options = {
            action: "resetPwdLong",
            value: {
              token: token,
              password
            }
          };

          await store.dispatch("authManagement/create", options);
          router.push({ name: "account-login" });
        }
        snackbar({
          text: `Your password has been reset!`,
          color: "success"
        });
      }
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to reset password. ${error}`,
        color: "error"
      });
    } finally {
      loading.value = false;
    }
  };

  return {
    passwordResetForm,
    isPasswordVisible,
    isConfirmPasswordVisible,
    loading,
    showPwnWarning,
    onSubmit,
    resetForm
  };
}

export function useRegisterUser() {
  const { route, router } = useRouter();

  const initialRegistrationForm = {
    email: route.value.query.email,
    password: null,
    newPassword: null,
    confirmPassword: null
  };

  const registrationForm = ref({ ...initialRegistrationForm });

  const isPasswordVisible = ref(false);
  const isNewPasswordVisible = ref(false);
  const isConfirmPasswordVisible = ref(false);

  const loading = ref(false);
  const showPwnWarning = ref(false);

  const showRedirectMessage = ref(false);

  const resetForm = async () => {
    try {
      registrationForm.value = initialRegistrationForm;
    } catch (error) {
      console.error("Unable to clear form values");
    } finally {
      await nextTick();
    }
  };
  const onSubmit = async (registrationForm) => {
    try {
      //showRedirectMessage.value = true;

      const numPwns = await pwnedPassword(registrationForm.newPassword);

      if (numPwns > 0) {
        // the password has been pwned
        // reject the password change attempt
        showPwnWarning.value = true;
        snackbar({
          text: `The password you are trying to use has been blacklisted. 
          Please submit a different one.`,
          color: "error",
          timeout: 7000
        });

        // resetForm();
      } else {
        // verify the user's email
        await store.dispatch("authManagement/create", {
          action: "verifySignupLong",
          value: route.value.query.token
        });

        // change their password from the temp password to their chosen one
        let oldPassword = registrationForm.password;
        let password = registrationForm.newPassword;
        let email = registrationForm.email;

        let options = {
          action: "passwordChange",
          value: {
            user: {
              email: email
            },
            oldPassword,
            password
          }
        };

        await store.dispatch("authManagement/create", options);

        // re-authenticate user instead of redirecting them to Login

        // const response = await store.dispatch("auth/authenticate", {
        //   strategy: "local",
        //   email,
        //   password
        // });

        // localStorage.setItem("tpm-jwt-refresh", response.refreshToken);
        // localStorage.setItem("tpm-uid", response.user.id);

        snackbar({
          text: `You're in!`,
          color: "success"
        });
      }
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to update password. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      //showRedirectMessage.value = false;
      resetForm();
      router.push("/account/login");
    }
  };

  return {
    registrationForm,
    isPasswordVisible,
    isNewPasswordVisible,
    isConfirmPasswordVisible,
    loading,
    showPwnWarning,
    showRedirectMessage,
    onSubmit,
    resetForm
  };
}
